.flexAllCol{
    display: flex;
    flex-direction: column;
}
.flexAll{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartItem{
    margin-top: 20px;
    width: 600px;
    background-color: rgba(232, 231, 231, 0.768);
    height: 100px;
    display: flex;
}
.cartItem .name{
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
    align-items: start;
}
.cartItem .price{
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    justify-content: center;
    align-items: end;
}