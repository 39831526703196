.flexAllWrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.flexAll{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexAllCol{
    display: flex;
    flex-direction: column;
}
.card{
    width: 250px;
    margin: 20px 10px 20px 10px;
    height: 320px;
    background-color: rgba(232, 231, 231, 0.768);
    font-size: 15px;
}
.card img{
    height: 250px;
    width: 250px;
}
.card .pp1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    align-items: center;
}
.card .pp{
    margin-left: 10px;
}
.card .ppr{
    margin-right: 10px;
}
.card .pname{
    font-size: 20px;
    font-weight: bolder;
}
.card .spp{
    font-size: 13px;
    background-color: rgb(4, 73, 87);
    color: white;
    border: 1px solid rgb(4, 73, 87);
    border-radius: 3px;
    height: 25px;
    width: 100px;
    cursor: pointer;
}